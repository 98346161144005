import googlePlacesRequest from './google-reviews-request';


const reviewMore = async () => {
    var target_ul = document.getElementById("google-reviews");
    var service = new google.maps.places.PlacesService(target_ul);
    
    const request = {
        placeId: 'ChIJmw0528_f3EcRRVlZGVz9c-4'
    };
    
    await service.getDetails(request, (place) => console.log(place.reviews));
    console.log("reviews chargées");
     

    // const response = await googlePlacesRequest()
    // if(response){
    //     // console.log(response);
    //     // document.addEventListener('DOMContentLoaded', function(event) {
    //         // setTimeout(function(){ 
    
                    
    //         const googleReviews = document.querySelectorAll(".review");
    //         console.log(googleReviews);

    //         for (let i = 0; i < googleReviews.length; i++) {
    //             const review = googleReviews[i];
    //             const reviewBtn = review.querySelector('.review-more');
    //             const reviewText = review.querySelector('.review-text');
                
    //             reviewBtn.onclick = () => {
    //                 reviewText.classList.toggle('review-text--open');

    //                 reviewText.classList.contains('review-text--open') ? reviewBtn.innerHTML = 'Lire moins&hellip;' : reviewBtn.innerHTML = 'Lire plus&hellip;';
    //             } 
    //         }
    //         // }, 1000);
    //     // })
    // }
}

export default reviewMore;

