const offerSwitch = () => {   
    if (document.body.hasAttribute('data-page', 'home')){
        var switchBtnPro = document.querySelector("[data-switch='professionnels']");
        
        if(switchBtnPro){
            switchBtnPro.addEventListener('click', function(){
                sessionStorage.setItem("switch", "pros")
            })
        }
    }

    if (document.body.hasAttribute('data-page', 'offers')){
        var switchBtn = document.querySelector('#switch-btn')
        const contactBlock = document.querySelector("[data-block='offers-contact']");
        
        if (sessionStorage.getItem("switch")) {
            document.body.classList.add('professionnals')
            contactBlock.classList.toggle('d-none')
            document.body.classList.remove('customers')
            sessionStorage.removeItem("switch");
        }
        
        if(document.body.classList.contains('professionnals')){
            switchBtn.classList.toggle('display-custs')
            switchBtn.classList.toggle('display-pros')
        }
        
        if(switchBtn){
            switchBtn.addEventListener('click', function(){
                const videoContainer = document.querySelectorAll(".gaetan-video")
                videoContainer.forEach(video => video.src = video.src);
                
                this.classList.toggle('display-pros')
                document.body.classList.toggle('customers')
                contactBlock.classList.toggle('d-none')
                document.body.classList.toggle('professionnals')
            })
        }    
    }
}

export default offerSwitch;
