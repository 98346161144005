
import googlePlaces from './google-maps-reviews';

const googlePlacesRequest = () => {
	if (document.body.getAttribute('data-page') === 'home' ) {
		googlePlaces('google-reviews', {
			placeId: 'ChIJmw0528_f3EcRRVlZGVz9c-4',
			max_rows: 3,
			shorten_names: false,
			stars: true,
			min_rating: 4
		});
	}
}

export default googlePlacesRequest;

